import React, {useCallback, useEffect} from 'react';
import './App.scss';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from 'i18next-http-backend'
import MultiLoadBackendAdapter from 'i18next-multiload-backend-adapter'
import config from './i18n.common'
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

declare global {
    interface Window {
        grecaptcha: any;
        sg: {
            launch: any
        };
    }
}
const savedLanguage = window.location.pathname.split('/').filter(Boolean)[0] ?? localStorage.getItem('selectedLanguage') ?? 'en';

i18n.use(initReactI18next)
    .use(MultiLoadBackendAdapter)
    .init({
        //defaultNS: 'web',
        keySeparator: false,
        ns: config.ns,
        lowerCaseLng: true,
        backend: {
            backend: HttpApi,
            backendOption: {
                loadPath: 'https://api.oshcasino.com/api/translations/{{ns}}',
                customHeaders: () => ({
                    'accept-language': savedLanguage,
                    // ...
                }),
                parse: (dataString: any, language: any) => {
                    const data = JSON.parse(dataString)
                    const lang = language[0]
                    return {[lang]: data};
                }
            }
        },
        lng: "en",
        updateMissing: true,
        nsSeparator: ':',
        fallbackLng: false,
        ignoreJSONStructure: true
    });

const paymentMethods = [
    "mastercard",
    "interac",
    "visa",
    "jeton",
    "bitcoin",
    "litecoin",
    "dogecoin",
    "astropay",
    "mbway",
    "multibanco",
    "mifinity",
    "pix",
    "blick",
    "Cash2Code",
    "Flexepin",
    "LuxonPay",
    "ethereum",
    "bank",
    "Ripple",
    "USDT",
    "Cryptocurrencies",
]

const bonusLink = `https://oshcasino.com/${savedLanguage}/promotions/bonus/welcome-pack`;

function App() {
    const queryParams = new URLSearchParams(window.location.search);

    const bypassParams = [
        'affid',
        'subid',
        'subid1',
        'subid2',
        'subid3',
        'subid4',
        'subid5',
        'subid6',
        'subid7',
        'subid8',
        'subid9',
        'subid10',
        'cid'
    ];

    const generateLink = useCallback(() => {
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());

        const bag: any = {};
        for (const index in bypassParams) {
            const name = bypassParams[index];
            const value = queryParams.get(name)
                || cookies.find(cookie => cookie.startsWith(name + '='))?.split('=').slice(1).join('=');

            if (value) {
                bag[name] = value;
            }
        }

        const queryString = Object.keys(bag)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(bag[key]))
            .join('&');

        return `${bonusLink}?${queryString}`;
    }, []);

    useEffect(() => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);
        const expirationDateString = expirationDate.toUTCString();

        for (const index in bypassParams) {
            const name = bypassParams[index];
            const value = queryParams.get(name);
            if (value) {
                document.cookie = `${name}=${value}; expires=${expirationDateString}; path=/`;
            }

        }
    }, []);

    const FIRST_IMAGE = {
        imageUrl: '/images/frame2.webp'
    };
    const SECOND_IMAGE = {
        imageUrl: '/images/frame1.webp'
    };

    const delimiterIconStyles = {
        width: '50px',
        height: '50px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: 'none',
        backgroundImage: 'url(/images/arrows.png)',
        backgroundColor: 'none'
    }

    const { t } = useTranslation();
  return (
    <div className="app">
        <div className='content'>
            <div className="content__action">
                <div className="content__action--text">{t('web.bonus-landing:WORKED HARD BEFORE,')}</div>
                <div className="content__action--acent-text">{t('web.bonus-landing:NOW spinning slots at home')}</div>
                <div className="content__action--bold-text">{t('web.bonus-landing:100% BONUS + 100 FS')}</div>
                <div className="content__action--button">
                    <a href={generateLink()} target="_blank" rel="noopener noreferrer" className="main-button">
                        {t('web.bonus-landing:Get Bonus')}
                    </a>
                </div>
            </div>
            <img draggable="false"
                 className="content__logo"
                 src="/logo.png"
                 alt="OSH logo"
                 loading="lazy"
            />
        </div>
        <ReactBeforeSliderComponent
            firstImage={FIRST_IMAGE}
            secondImage={SECOND_IMAGE}
            delimiterColor="#00C74D"
            delimiterIconStyles={delimiterIconStyles}
        />
      <footer className="footer">
          <div className="payment">
              {paymentMethods.map((item) =>
                  <img draggable="false"
                       className="payment__item"
                       src={`/payment/${item}.png`}
                       alt="OSH logo"
                       loading="lazy"
                       key={item}
                  />
              )}
          </div>
      </footer>
    </div>
  );
}

export default App;
